import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { down, up } from "styled-breakpoints"
//DATA
import { info } from "../assets/data/kontakt"
import film from "../assets/video/film.mp4"
//STYLES
import { H1, H2, H3, H4, H5, H6, Text, LabelBW } from "../utils/typo"
import {
  Div,
  Section2,
  ContainerMob,
  CardWrapperFull,
  CardWrapperFull2,
  ContainerThin,
} from "../utils/utils"
//COMPONENTS
import Seo from "../components/seo/Seo"
import Button from "../components/button/Button"
import SeoImage from "../components/seo/SeoImage"

const Baner = styled.div`
  position: absolute;
  width: 100%;
  max-width: 2560px;
  height: auto;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  > div:nth-of-type(2) {
    display: none;
  }

  ${down("xs")} {
    > div:nth-of-type(1) {
      display: none;
    }
    > div:nth-of-type(2) {
      display: block;
    }
  }

  :before {
    content: "";
    background: rgb(0, 46, 12);
    background: linear-gradient(
      180deg,
      rgba(0, 46, 12, 0.85) 0%,
      rgba(24, 77, 0, 0.75) 50%,
      rgba(96, 143, 11, 0.65) 100%
    );
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    z-index: 2;
  }
`

export default function Onas() {
  return (
    <div>
      <Seo title="Rotkegel - producent roślin najwyższej jakości" slug="/" />
      <SeoImage />
      <Baner>
        <StaticImage
          src="../assets/images/2.jpg"
          alt="Obrazy w ramkach - idealny upominek na dzieńkobiet"
          layout="fullWidth"
          objectFit="cover"
          placeholder="blurred"
          aspectRatio={3.5}
        />
        <StaticImage
          src="../assets/images/2.jpg"
          alt="Obrazy w ramkach - idealny upominek na dzieńkobiet"
          layout="fullWidth"
          objectFit="cover"
          placeholder="blurred"
          aspectRatio={1.3}
        />
      </Baner>
      <ContainerMob mt="4rem" mtMobile="2rem">
        <H1 colorFont="white" textAlign="center">
          Rodzinne gospodarstwo z pasją
        </H1>
      </ContainerMob>
      <Section2 pt="7" pb="7" mt="6rem" bgcolor="white">
        <ContainerMob>
          <H2>Jesteśmy producentem roślin</H2>
          <Text mt="2rem" mb="1rem">
            <b>
              Nasze gospodarstwo zajmuje się roślinami od ponad 30 lat. Jesteśmy
              rodzinną firmą, w której miłość do roślin została zakorzeniona już
              do kolejnego pokolenia.
            </b>
          </Text>
          <Text>
            Rośliny w naszym gospodarstwie są pod stałą opieką wykwalifikowanych
            i pełnych pasji ogrodników z wieloletnim doświadczeniem. Posiadamy
            nowoczesny system nawadniania, który zapewnia bardzo dobre warunki
            dla roślin, a jednocześnie stanowi doskonałą ekspozycję.
          </Text>
        </ContainerMob>
      </Section2>
    </div>
  )
}
